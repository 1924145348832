import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';

import logo from 'img/help/integrations/reactangular.jpg';

const Content = () => {
  return (
    <div>
      <p>
        LiveSession provides a SDK package to install script in your favorite framework. This tool
        also let you work easier with LiveSesion API. We&apos;ve prepared some examples how to
        install tracking code in React and Angular.
      </p>
      <p>In both cases start with installing LiveSesion SDK</p>
      <Code style={{ margin: '1rem 0' }}>npm i @livesession/sdk</Code> <p>or</p>
      <Code style={{ margin: '1rem 0 1.5rem' }}>yarn add @livesession/sdk</Code>
      <SubTitle>React usage</SubTitle>
      <p>
        We recommend two ways to integrate SDK with your app. First of all, import a package into
        your project
      </p>
      <Code copy style={{ margin: '2.5rem 0 1rem' }}>
        {` import ls from "@livesession/sdk";`}
      </Code>{' '}
      <ol>
        <li>
          <p>Init script in file where you&apos;re rendering a whole application:</p>
          <Code margin="2.5rem 0 2rem" copy lang="javascript">
            {`import ls from "@livesession/sdk";

ls.init("YOUR-TRACK-ID");
ls.newPageView();
ReactDOM.render(<App />, document.getElementById("root"));`}
          </Code>
        </li>
        <li>
          <p>
            If you are using something like Layout component, you can also use lifecycle method
            componentDidMount in it.
          </p>
          <Code margin="2.5rem 0 2rem" copy lang="javascript">
            {`import ls from "@livesession/sdk";

class Layout extends Component {
   componentDidMount(){
       ls.init("YOUR_TRACK_ID");
       ls.newPageView();
   }
   render() {
       return (
           // your layout
       );
   }
}
export default Layout;`}
          </Code>
          <p>If you have React 16.8 or higher - try with useEffect hook</p>
          <Code margin="2.5rem 0 2rem" copy lang="javascript">
            {`import ls from "@livesession/sdk";

const Layout = () => {
    useEffect(() => {
        ls.init("YOUR_TRACK_ID");
        ls.newPageView();
    },[])
    return (
        // your layout
    )
}
export default Layout;`}
          </Code>
        </li>
      </ol>
      <SubTitle>Angular usage</SubTitle>
      <ol>
        <li>
          <p>Import SDK into your main app component</p>
        </li>
        <li>
          <p>
            Import <Code inline>OnInit</Code> from <Code inline>@angular/core</Code>
          </p>
        </li>
        <li>
          <p>
            Implement <Code inline>OnInit</Code> and call LiveSession init method in{' '}
            <Code inline>ngOnInit</Code> function
          </p>
          <Code margin="2.5rem 0 2rem" copy lang="javascript">
            {`// app.component.ts
import ls from '@livesession/sdk'
export class AppComponent implemets OnInit {
    ngOnInit(){
        ls.init("YOUR_TRACK_ID");
        ls.newPageView();
    }
}`}
          </Code>
        </li>
      </ol>
      <p>
        If you want to know more informations about configuration, go to{' '}
        <a
          href="https://developers.livesession.io/javascript-api/configuration/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LiveSesion Developers
        </a>{' '}
        or read instructions on LiveSesion SDK&apos;s{' '}
        <a
          href="https://github.com/livesession/livesession-sdk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github page
        </a>
        .
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works?',
    description: 'Check if your tracking code is installed correctly.',
  },
  {
    link: '/help/how-livesession-script-works/',
    title: 'How LiveSession script works?',
    description: 'Undarstand what our tracking code tracks.',
  },
];

export const frontmatter = {
  metaTitle: 'How to install LiveSession with React or Angular application?',
  metaDescription: 'Integrate LiveSession with your favourite framework.',
  canonical: '/help/react-and-angular-integration/',
  logoStyle: { maxWidth: 150 },
  logo,
};

const Wrapped = HelpArticle(Content);

export default () => (
  <Wrapped related={related} section="Get Started" title={frontmatter.metaTitle} {...frontmatter} />
);
